.contentScroll{
    overflow: auto;
    height:725px;
}
.uploadContentScroll {
    height: 400px;
    overflow: auto;
}
.contentCard{
    margin: 10px 0 !important;
    width: 100% !important; 
    padding: 1.2rem 0 !important;
    border: 1px solid #0f00f9;
    background-color: #f7faff;
    border-radius: 10px;
    box-shadow: 0 3px 1px rgb(22 22 22 / 17%);
}
.btnLight{
    background-color: #e9356f !important;
    /* border-color: #212529 !important; */
    border: 1px solid #e9356f !important;
    margin-top: 7px;
    color: #fff;
}
.btnLight:hover{
    background: #fff !important;
    color:#e9356f !important;
}
.dragText{
    font-size: 15px;
    font-weight: 600;
}
.limitText{
    font-size: 13px;
    margin-top: 2px;
    color: gray;
}
.uploadText{
    font-size: 13px;
}
.fileName{
    margin-top: 1rem;
    border: 1px solid #fff;
    margin-bottom: 1.5rem;
}
.jqaiPj {
    background-color: rgb(255 255 255) !important;
}

.download-btn-container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.download-btn-box
{
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background-color: white;
    border-radius: 5px;
    font-size: 12px;
    padding: 5px;
    z-index: 9;
    position: absolute;
    top: 36px;
  

}
.download-btn-box .download-btn
{
    background-color: transparent;
    padding: 5px;
    width: 100%;
}

.download-btn-box .download-btn:hover{
    background-color: #e9356f;
    color: white;
}

.editContent {
    width: 600px;
    font-size: 14px;                               
    height: 40px;                             
}

.markdown-content table {
    border-collapse: collapse;
    width: 100%;
  }

.markdown-content th, td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 5px;
}

.markdown-content th {
    background-color: #f2f2f2;
}
.mainContent {
    position: relative;
    display: grid;
    grid-template-rows: 91% 8%;
    width: 100%;
    gap: 1%;
    height: 89vh;
    /* border-radius: 8px; */
  }
  .uploadRagaScore {
    font-size: 14px;
    color: #000;
}
.uploadScoreCard {
    background: #f8fafe;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #0f00f9;
    box-shadow: 0 3px 1px rgb(22 22 22 / 17%);
}
