



.generateBtnContainer
{
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  left: 40%;
  top: 82%;
  z-index: 1000;
  border: 1px solid rgb(204, 204, 204);
  background-color: whitesmoke;
  border-radius: 5px;
  min-width: 25px;
  padding: 10px;
}