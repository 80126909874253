

.loginbutton {
    background: #ea356f;
    border: none;
    color: white;
    padding: 10px 28px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    border-radius: 5px;
  }
  
  .bgTransprent
  {
    background-color: transparent;
  }
  button:focus
  {
    outline: none;
  }

  .outlineBtn
  {
    padding: 10px;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 0px 2px 2px rgba(228, 227, 227, 0.15);
    font-size: 0.9rem;
    height: 40px;
    background-color: transparent;
    color: #4c85f7;
    border: 1px solid #4c85f7;
    font-weight: 600;
    font-size: 12px;
  }
  .outlineBtn:hover
  {
    cursor: pointer;
  }


  .outlineBtnDisable
  {
    background-color: #eeedede0;
  }
  .outlineBtnDisable:hover
  {
    cursor: not-allowed;
  }