.contentScroll{
  height: 77vh;
  overflow-y: auto;
}

.contentScroll-medPulse{
  height: 77vh;
  overflow-y: auto;
}

/* new Ui */
.tabList-sentiment{
  margin-left: "4%"; 
  margin-top: "3%";
}

.tab-sentiment{
  color: "#718096";
  font-size: "16px";
  font-weight: 600;
  padding: "30px";
  cursor: "pointer";
}

.medInsightLabel {
  font-size: 1rem;
  color: #333;
}

.row .col-md-4,
.row .col-md-2 {
  display: flex;
  align-items: center;
}

.saveButton-medInsights{
  width: 8.5vw;
  font-size: 0.85rem;
  padding: 9px 33px;
  font-weight: 500;
  background: #49CB90;
  border: 0.5px solid #49CB90;
  border-radius: 6px;
  opacity: 1;
  color: black;
  font-weight: bolder;
}

/* chatbot */
.chatbot-container {
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 1000;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.botIcon{
  width: 60px;
  height: 60px;
  position: fixed;
  cursor: pointer;
  bottom: 30px;
  right: 50px;
}