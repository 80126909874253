.desc-tab__tables {
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;
  text-align: left;
}
.table-sticky {
  width: 100%;
  max-height: 400px; /* Adjust height as needed */
  overflow-y: auto;
}

.desc-tab__tables__rowheading th {
  background-color: #f2f2f2;
  border-bottom: 1px solid #dddddd;
  padding: 12px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.desc-tab__tables__rowborder th {
  border-bottom: 2px solid #dddddd;
  padding: 12px;
}

.desc-tab__tables__databorder td {
  border-bottom: 1px solid #dddddd;
  padding: 12px;
}

.desc-tab__tables tr:hover {
  background-color: #f1f1f1;
}

.desc-tab__tables__databorder {
  padding: 12px;
}

.desc-tab__tables th,
.desc-tab__tables td {
  padding: 12px;
}
.sentences{
  max-width: 100%;
}
