@media (max-width: 768px) {
  .d-flex {
    flex-direction: row;
    align-items: flex-start;
  }

  .d-flex label {
    font-size: 0.7rem;
  }
}
