#react-select-2-listbox
{
  display: block !important;
  z-index: 99;
}
#react-select-26-listbox
{

  z-index: 999 !important;
}

.rs-table-header-row-wrapper
{
  z-index: unset !important;
}