.personal_tableRed .rs-table-cell-header .rs-table-cell-content {
    background-color: #fef7f7;
}
.personal_tableRed .rs-table-row:nth-child(even) .rs-table-cell-content {
    background-color: #fef7f7 !important;
}

.personal_tableOrange .rs-table-cell-header .rs-table-cell-content {
    background-color: #fffcf8;
}
.personal_tableOrange .rs-table-row:nth-child(even) .rs-table-cell-content {
    background-color: #fffcf8 !important;
}
.red_h6{
    background: #ffafaf;
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
}
.orange_h6{
    background: #fed799;
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
}
.location_h6{
    background: #4C85F7;
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
}
.tableBG{
    background: #f6f6f678;
}
.tabImgCard{
    border-radius: 10px;
    padding: 15px;
    box-shadow: 2px 2px 2px #ebe9e9;
    background: #f6f6f678;
    width: 255px;
}
.tabBG {
    /* background: #f6f6f678; */
    padding: 5px;
}
.tabCard{
    border-radius: 10px;
    padding: 15px;
    box-shadow: 2px 2px 2px #ebe9e9;
    height: 500px;
    overflow: auto;
    background: #f6f6f678;
}
.personal_table_pag_color .rs-picker-toggle-value{
    color: #000000 !important;
}
.location_span {
    font-size: 14px;
    padding-left: 5px;
}
.imgContainer {
    width: 230px;
    height: 150px; 
    margin-bottom: 10px;
}

.img-text .img-flex {
font-size: 14px;
}

.imgContainer img{
    width: 100%;
    height: 100%;
    object-fit: fill;   
}
.ratio-16x9{
    aspect-ratio: 16 / 9;
}

.contentCenter {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 70%; 
}

.content-Loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px; 
    margin: 0 auto;
  }

.mrlCardRed .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 5px;
}

.mrlCardOrange .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 5px;
}
.flag_li li{
    font-size: 14px;
}
.mrlCardRed {
    padding: 2px;
    border-radius: 5px;
    box-shadow: none;
    background: #ffafaf !important;
}

.mrlCardOrange {
    padding: 2px;
    border-radius: 5px;
    box-shadow: none;
    background: #fed799 !important;
}

.paperRed {
    background: #ffafaf;
    /* border: 1px solid #ccc; */
    padding: 2px;
    margin: 8px 2px;
}

.paperOrange {
    background: #fed799;
    /* border: 1px solid #ccc; */
    padding: 2px;
    margin: 8px 2px;
}

.card-header {
  border-bottom: 0;
}

.paperContainer {
    height: 500px;
    overflow: auto
}

.box ul {
    background: #f5f5f5d1;
    border-radius: 5px;
    border: 1px solid #eee;
    margin-left: 0px;
    height: 120px;
    width: 100%;
    padding-left: 1.75rem;
    padding-top: 0.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.box {
    /* background: #ddd; */
    /* border: 1px solid #ddd; */
    /* border-radius: 5px; */
    margin-left: 10px;
    padding: 5px !important;
}

.mrlCardSpan {
    font-size: 18px;
    font-weight: 600;
    margin-left: 1rem;
}

.box li {
    font-size: 14px;
}

.uploadFileScroll {
    height: 600px;
    overflow: auto;
}

.tabBG ul {
    padding-left: 1.25rem;
}
.tab-content {
    width: 100%;
}
.tab-content .contentCard{
    margin: 0 !important;
    /* border: 1px solid #0f00f9 !important; */
    border: transparent !important;
    border-radius: 0px !important;
    border-top: none !important;
}
.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}
.tabNavs .nav-item {
    color: #495057;
    background-color: #fff !important;
    /* border-color: #0f00f9 #0f00f9 #fff; */
    width: 300px;
}
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #0f00f9 #0f00f9 #fff !important;
}
.tabNavs.nav-tabs .nav-link {
    background: #fff;
    border-bottom: 1px solid #0f00f9;
}
.nav-justified .nav-item .nav-link {
    width: 100%;
}

.nav-tabs .nav-link {
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.para_align {
    white-space: break-spaces;
    /* max-height: 300px; */
}

.tableBG .rs-table {
    position: relative;
    overflow: auto !important;
}
.watermark-contentLoader{
    height: 200px;
    width: 500px;
}

.pdf-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: auto;
}
  
.claims-sidebar {
    width: 325px;
    padding: 10px;
    overflow-y: auto;
    height: 480px;
    background: #eee;
}
  
.claim {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}
.claim_card {
    width: 300px;
    margin: 0 auto;
    padding: 10px;
    background-color: whitesmoke;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
