@import "src/scss/colors";
@import "src/scss/variables";

.current-session {
  &__newchat {
    margin-top: 25px;
    cursor: pointer;
    @include flexContainer(center, center);
    &__btn {
      font-size: 0.85rem;
      padding: 9px 33px;
      font-weight: 500;
      background: $orange65 0% 0% no-repeat padding-box;
      border: 0.5px solid $orangea8;
      border-radius: 6px;
      opacity: 1;
      color: $black;
    }
  }

  &__border {
    border-top: 1px solid $grayc1;
    border-bottom: 1px solid $grayc1;
    &__titlewrap {
      @include flexContainer(center, null);
      &__title {
        margin-left: 10px;
        font-weight: 600;
      }
    }
  }

  &__active {
    margin-left: -2%;
    @include flexContainer(center, null);
    white-space: nowrap;
    border: 0.5px solid $orangea8;
    border-radius: 4px;
    padding: 5px 5px 5px 10px;
    overflow: scroll;
    font-size: 0.8rem;
    width: 100%;
  }

  &__inputwrap {
    @include flexContainer(center, null);
    width: 88%;
    &__input {
      border: none;
      padding: 2px 3px;
      color: black;
      overflow: hidden;
      flex: 1;
    }
    &__inputvalue {
      flex: 1;
      overflow: scroll;
    }
  }

  &__iconwrap {
    &__iconwhite {
      color: $white;
    }
  }
}
.fileListMenu ul {
  padding-left: 0;
  margin-top: 1rem;
}
.fileListSpan {
  padding-left: 0.5rem;
}
