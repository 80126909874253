.sideLinkMenu a {
    color: #fff;
    padding-left: 0.5rem;
}

:root {
    --primary: #e9356f;
    --secondary: #fffff;
    --border-color: #ebd4e8;
  }

  .maxiBtn
  {
    color:  #4C85F7;
  }

  .sideLinkMenu input[type="radio"] {
    display: none; /* Hide radio buttons within SidebarNavFiles component */
  }

  label {
    display : flex;
    padding :10px;
    gap :1em;
    align-items : center
  }
  
  .labelcontainer {
    width :25px;
    aspect-ratio : 1;
    overflow : hidden;
    border-radius : 50%;
    box-shadow : 2px 5px 5px rgba(0,0,0,0.2);
  }
  
  .labelcRadioBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #fff;
    position: relative;
    border: solid 5px var(--border-color);
    box-sizing: border-box;
    transition: all 0.1s ease-in;
    cursor: pointer;
    
  }
  .custom-margin {
    margin-top: 6.5rem; 
  }

  .labelcRadioBtn:hover {
    border: solid 5px var(--primary);
  }
  
  .labeloverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background: var(--secondary);
  }
  
  .check:checked + label > .labelcontainer > .labelcRadioBtn > .labeloverlay {
    background: #fff;
    animation: shrink 0.3s ease-in-out forwards;
  }
  
  .check:checked + label > .labelcontainer  > .labelcRadioBtn {
    background: var(--primary);
    border-color: var(--primary);
  }
  
  .drops {
    position: absolute;
    background: #fff;
    /* top: -100%; */
    left: 50%;
    transform: translate(-50%);
    border-radius: 50%;
    aspect-ratio: 1;
  }
  
  .lgDrop {
    width: 50%;
  }
  
  .xsDrop {
    width: 20%;
  }
  
  .mdDrop {
    width: 30%;
  }
  
  .check:checked + label > .labelcontainer  > .labelcRadioBtn > .lgDrop {
    animation: drop 0.5s ease-in-out forwards;
  }
  
  .check:checked + label > .labelcontainer  > .labelcRadioBtn > .mdDrop {
    animation: drop 0.8s ease-in forwards;
  }
  
  .check:checked + label > .labelcontainer  > .labelcRadioBtn > .xsDrop {
    animation: drop 0.9s linear forwards;
  }
  
  @keyframes drop {
    0% {
      top: -100%;
    }
    50% {
      top: -100%;
    }
    75% {
      top: 10%;
    }
    100% {
      top: 25%;
    }
  }
  
  @keyframes shrink {
    0% {
      width: 100%;
    }
    100% {
      width: 0px;
    }
  }

  .rs-table-cell-content {
    color: #000 !important;
}

.btnSearch {
  font-size: 20px;
  width: 40px;
  height: 40px;
  line-height: 45px;
  border-radius: 300px;
  z-index: 999;
  cursor: pointer;
  outline: none;
  background: cornflowerblue;
  color: #fff;
  border: none;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.3);
}
.searchContainer{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.searchContainer input[type="search"]{
  width: 0;
  transition: 0.3s;
  border-radius: 5px;
  margin-left: -18px;
  font-size: 16px;
  border: none;
  outline: none;
}

input[type="search"].exp-search-show {
  width: 300px;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.3);
}
.midLingoLabel{
  font-size: 13px;
  padding-bottom: 10px !important;
  float: left;
  width: 100%;
  padding-left: 1rem;
}
.medLingo_select .MuiSelect-select {
  padding: 8px;
} 

.rs-table-cell-header .rs-table-cell-content {
  background-color: #F7FAFE;
}

.rs-table-row:nth-child(even) .rs-table-cell-content {
  background-color: #F7FAFE !important; /* Set the background color for even rows */
}

.content-lingo{
  font-size: 14px;
  margin-bottom: 1rem;
}
.medInsightLabel{
  padding-left: 1rem !important;
  margin-bottom: 0 !important;
  font-size: 16px;
}
span.countryName {
  margin-left: 6px;
  text-transform: uppercase;
}

.contentCardInMlt {
    margin: 10px 0 !important;
    width: 100% !important;
    padding: 1.2rem 0 !important;
    border: 1px solid #0f00f9;
    background-color: #f7faff;
    border-radius: 10px;
    box-shadow: 0 3px 1px rgb(22 22 22 / 17%);
}

.addButton{
  width: 8.5vw;
  font-size: 0.85rem;
  padding: 9px 33px;
  font-weight: 500;
  background: #FFC965;
  border: 0.5px solid #FFC965;
  border-radius: 6px;
  opacity: 1;
  color: black;
  font-weight: bolder;
}

.saveButton{
  width: 8.5vw;
  font-size: 0.85rem;
  padding: 9px 33px;
  font-weight: 500;
  background: #49CB90;
  border: 0.5px solid #49CB90;
  border-radius: 6px;
  opacity: 1;
  color: black;
  font-weight: bolder;
}