.dashboardmainContent {
  position: relative;
  height: 89vh;
  border-radius: 8px;
  overflow: scroll !important;
}

.details {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.dashboardSummarize {
  margin-left: 37%;
  display: flex;
  align-items: center;
}

.summarize {
  margin-left: 2%;
  padding: 8px 10px;
  width: 20%;
  background: #4c85f7;
  color: white;
  border-radius: 8px;
}

.clear {
  padding: 8px 10px;
  width: 20%;
  cursor: pointer;
  background-color: transparent;
  color: black;

}

.fields {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  width: 100% !important;

}

.formLayout {
  position: absolute;
  left: 2.5%;
  margin-top: 2%;
  width: 95%;
  z-index: 1;
}

.headingdashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 63px;
  color: #030a1d;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 3px;
}

.headingdashboard>h4 {
  font-weight: 550;
  font-style: normal;
}

.formDetails {
  position: absolute;
  background-color: white !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 20px;
  width: 100%;
  max-height: 70vh;
  overflow: scroll !important;
  border-radius: 0px 0px 5px 5px;
  padding-bottom: 10px;
  overflow: scroll !important;
  z-index: 2;
}


.dashboardQueryTop {
  height: 75.7vh !important;
  margin-top: 8%;
}

.summarizeBtn {
  border-radius: 6px;
  padding: 8px 15px;
  cursor: pointer;
  font-size: 0.9rem
}

@media (max-width: 768px) {
  .summarizeBtn {
    font-size: 0.7rem;
    padding: 5px 10px;
  }

  .dashboardSummarize {
    margin-left: 10%;
  }

  .summarize,
  .clear {
    width: 30%;
    padding: 6px 8px;
    font-size: 0.75rem;
  }

  .formDetails {
    padding: 15px;
    max-height: 60vh;
  }

  .d-flex.align-items-center.justify-content-around {
    flex-direction: column;
    gap: 15px;
  }

  .d-flex label {
    font-size: 0.7rem;
    width: 130px;
  }

  .d-flex input {
    font-size: 1rem;
    width: 100%;
    margin-left: 10px;
  }
}