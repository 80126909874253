@import "src/scss/colors";
@import "src/scss/variables";

.h-45 {
  height: 45px;
}

.slick-slide>div {
  margin-right: 10px;
}

.slick-prev:before {
  color: $btnred_6f !important;
}

.slick-next::before {
  color: $btnred_6f !important;
}

.slick-prev {
  left: -20px !important;
}

.slick-next {
  right: -22px !important;
}

.expand-menu {
  position: absolute;
  background: $white 0% 0% no-repeat padding-box;
  transition: 0.4s ease-out;
  padding: 20px;
  top: 9vh;
  overflow: scroll !important;
  padding-bottom: 10px;
  z-index: 101;
  width: 100%;

  &__menu-items {

    // @include flexContainer(null, null);
    .menu_disable {
      cursor: not-allowed;
    }

    &__menubox {
      @include flexContainer(center, center);
      flex-direction: column;
      border: 2px solid $primary;
      color: $btnred_6f;
      padding: 9px 4px;
      width: 15%;
      height: 120px;
      font-weight: 600;
      border-radius: 6px;
      background: $white 0% 0% no-repeat padding-box;
      margin: 0px 10px;
      cursor: pointer;
      font-size: 12px;
      text-align: center;

      p {
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        text-align: center;
      }

    }

    &__menu-img {
      height: 70px;
      @include flexContainer(center, center);

      .imgwidth {
        height: 78px;
      }

      &__img {
        height: 40px;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &__icon {
    @include flexContainer(null, center);
  }
}