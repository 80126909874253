.content-heading-page {
    padding: 20px;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    height: 90vh;
}

.back-buttonHeading {
    background: none;
    border: none;
    color: black;
    background-color: white;
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 20px;
    align-self: flex-start;
}

.back-buttonHeading:hover {
    background-color: whitesmoke;
}

.contentHeading {
    margin-bottom: 20px;
}

.title-inputHeading {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.title-inputHeading label {
    margin-right: 70px;
}

.title-inputHeading input {
    flex: 1;
}

#title {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
}

.include-citations {
    display: flex;
    align-items: center;
    gap: 10px;
}

.include-citations-radio {
    display: flex;
    align-items: center;
    gap: 10px;
}

.include-citations span {
    margin-right: 10px;
    margin-left: 70px;
}

.include-citations-radio label {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0;
}

.table-heading {
    margin-top: 5px;
}

.table-heading h3 {
    margin-top: 5px;
}

.table-of-contents {
    flex: 1;
    margin-top: 10px;
    padding: 10px;
    overflow-y: auto;
    scrollbar-color: rgb(141, 140, 140) lightgrey;
    scrollbar-width: thin;
}

.table-of-contents.highlight {
    border: 2px dashed #035eb7;
    background-color: rgba(3, 94, 183, 0.1);
}

.table-of-contents::-webkit-scrollbar {
    width: 5px;
}

.table-of-contents::-webkit-scrollbar-track {
    background: lightgrey;
}

.table-of-contents::-webkit-scrollbar-thumb {
    background-color: rgb(148, 147, 147);
    border-radius: 7px;
}

.table-of-contents::-webkit-scrollbar-thumb:hover {
    background-color: darkgrey;
}

.heading-container {
    margin-bottom: 20px;
    padding-right: 10px;
}

.heading-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.heading-row label {
    width: 45px;
    text-align: right;
    font-size: 16px;
    color: #333;
}

.heading-input {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 2px;
    margin-left: 70px !important;
    max-width: 400px;
    width: 100%;
    padding: 8px;
}

.structure-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-width: 400px;
    width: 100%;
}

.subheading-container {
    max-height: 200px;
    overflow-y: auto;
}

.subheading-row {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.subheading-row label {
    width: 45px;
    text-align: right;
    font-size: 16px;
    color: #333;
}

.subheading-input {
    flex: 1;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-left: 95px;
    max-width: 400px;
    width: 100%;
    padding: 8px;
}

.subheading-row button {
    margin-left: 10px;
}

.add-button,
.delete-button {
    background-color: transparent;
    color: black;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 20px;
}

.delete-button {
    color: black;
}

.add-button:hover,
.delete-button:hover {
    background-color: whitesmoke;
    opacity: 0.7;
}

.form-actions {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
}

.generate-button {
    background-color: #027fff;
    padding: 10px 20px;
    border: 1px solid #027fff;
    color: white;
    border-radius: 4px;
}

.generate-button:hover {
    background: white;
    color: black;
    border-color: #027fff;
}

.heading-input.left,
.subheading-input.left {
    text-align: left;
}

.heading-input.center,
.subheading-input.center {
    text-align: center;
}

.heading-input.right,
.subheading-input.right {
    text-align: right;
}

.alignicons {
    color: black;
    size: 14px;
    cursor: pointer;
    margin: 10px;
    margin-top: 15px;
}

.alignicons:hover {
    background-color: whitesmoke;
}

.browse-heading-button {
    color: #027fff;
    cursor: pointer;
    padding: 8px;
    border: 2px dashed #027fff;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    width: 400px;
    margin-top: 20px;
    margin-left: 115px;
}

.browser-heading-icon {
    font-size: 24px;
    position: relative;
    top: 4px;
}

.browse-heading-button:hover {
    background-color: #eef7ff;
}

.loading-heading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-heading-box {
    background-color: white;
    padding: 40px !important;
    border-radius: 10px;
    box-shadow:
        0 4px 8px rgba(0, 0, 0, 0.2),
        0 -4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    width: 400px;
    height: 230px;
}

.loading-heading-animation {
    width: 100px;
    height: auto;
    margin: 0;
}

.loading-heading-dots {
    width: 60px;
    height: auto;
    margin-top: 7px;
    padding: 0;
}

.loading-heading-text {
    font-size: 20px;
    color: black;
    margin: 0;
    padding: 0;
}

.loading-heading-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    margin: 0;
    padding: 0;
}

.loading-heading-div span {
    margin-right: -10px;
}

.dim-content {
    filter: blur(4px);
    opacity: 0.6;
    pointer-events: none;
}