.ql-toolbar .ql-undo {
  display: inline-block;
  margin: 3px;
  font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
  color: black;
  transform: rotate(180deg);
}

.ql-toolbar .ql-redo {
  display: inline-block;
  margin: 3px;
  font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
  color: black;
  transform: rotate(180deg);
}

.ql-toolbar .ql-undo:hover,
.ql-toolbar .ql-redo:hover {
  color: #027fff;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 16px;
}

.ql-container.ql-snow {
  display: flex;
  height: 540px;
  width: 100% !important;
  font-size: 16px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  position: relative;
}

.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  width: 100%;
  scrollbar-color: rgb(141, 140, 140) lightgrey;
  scrollbar-width: thin;
  transition: width 0.3s ease;
}

.ql-editor.ql-editor-Min {
  width: 50% !important;
}

.ql-editor h1 {
  text-align: center;
}

.ql-snow .ql-picker-options [data-value="yellow"] {
  background-color: yellow;
}

.ql-snow .ql-picker-options [data-value="pink"] {
  background-color: pink;
}

.ql-snow .ql-picker-options [data-value="lightgreen"] {
  background-color: lightgreen;
}

.ql-snow .ql-picker-options [data-value="red"] {
  background-color: red;
}

.ql-size .ql-picker-options [data-value="8px"]::before {
  content: "8px";
  font-size: 8px;
}

.ql-size .ql-picker-options [data-value="10px"]::before {
  content: "10px";
  font-size: 10px;
}

.ql-size .ql-picker-options [data-value="12px"]::before {
  content: "12px";
  font-size: 12px;
}

.ql-size .ql-picker-options [data-value="14px"]::before {
  content: "14px";
  font-size: 14px;
}

.ql-size .ql-picker-options [data-value="16px"]::before {
  content: "16px";
  font-size: 16px;
}

.ql-size .ql-picker-options [data-value="18px"]::before {
  content: "18px";
  font-size: 18px;
}

.ql-size .ql-picker-options [data-value="24px"]::before {
  content: "24px";
  font-size: 24px;
}

.ql-size .ql-picker-options [data-value="36px"]::before {
  content: "36px";
  font-size: 36px;
}

.ql-size .ql-picker-options [data-value="48px"]::before {
  content: "48px";
  font-size: 48px;
}

.ql-size .ql-picker-options [data-value="72px"]::before {
  content: "72px";
  font-size: 72px;
}

.ql-editor .reference-span {
  color: blue !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}

.save-button,
.download-button,
.share-button {
  padding: 10px 20px;
  border: 1px solid #027fff;
  background: #027fff;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.save-button:hover,
.download-button:hover,
.share-button:hover {
  background: white;
  color: black;
  border-color: #027fff;
}

.editor-container {
  width: 95%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: Arial, sans-serif;
}

.editor-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.editor-buttons {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  margin: 10px;
  gap: 5px;
}

.back-button {
  background: none;
  border: none;
  color: black;
  background-color: white;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
  margin-right: 10px;
}

.back-button:hover {
  background-color: whitesmoke;
}

.back-button-arrow {
  background: none;
  border: none;
  color: black;
  background-color: whitesmoke;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
  margin-right: 10px;
}

.back-button-arrow:hover {
  background-color: white;
}

.react-quill-container {
  position: relative;
  flex-grow: 1;
}

.react-quill-container img {
  max-width: 100%;
  max-height: 400px;
  display: block;
  margin: 0 auto;
}

.ql-toolbar {
  border: 1px solid #ddd;
  border-radius: 5px 5px 0 0;
  padding: 5px;
}

.ql-toolbar button {
  padding: 5px;
  border: none;
  background: none;
  cursor: pointer;
}

.ql-toolbar button:hover {
  background: #f0f0f0;
  border-radius: 3px;
}

.ql-clean {
  font-size: 18px;
  color: #555;
}

.tooltip-button {
  position: absolute;
  padding: 3px 7px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.loaderImage-text {
  font-size: 14px;
  color: black;
  padding: 5px 10px;
  border: 2px solid #027fff;
  border-radius: 5px;
  background-color: whitesmoke;
  display: inline-block;
}

.loaderAI-text {
  margin-top: 20px;
  font-size: 14px;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: whitesmoke;
  display: inline-block;
}

.edit-ai-button {
  border: 1px solid #858585;
  background: whitesmoke;
  color: black;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 7px;

}

.edit-ai-button:hover {
  background: #d3d3d3;
  border: 1px solid #858585;
}

.modal-overlayeditor {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 2000;
}

.modaleditor {
  background: white;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transform: translateX(100%);
  animation: slideInFromRight 0.5s ease-out forwards;
  position: absolute;
  right: 0;
}

.modal-headereditor {
  flex-shrink: 0;
  background: #f5f5f5;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.left-section {
  display: flex;
  align-items: center;
  gap: 5px;
}

.edit-ai-title {
  display: flex;
  align-items: center;
  gap: 5px;
}

.back-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.icon-adjust {
  position: relative;
  top: 4px;
  font-size: 20px;
}

.close-button-chatbot {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}


@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.modal-header h3 {
  font-size: 18px;
  font-weight: bold;
  color: #444;
  margin: 0;
}

.back-button,
.close-button-chatbot {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #444;
}

.back-button:hover,
.close-button-chatbot:hover {
  color: #000;
  background-color: whitesmoke;
}

.modal-content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 80%;
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: grey transparent;
}

.modal-content-wrapper::-webkit-scrollbar {
  width: 7px;
}

.modal-content-wrapper::-webkit-scrollbar-track {
  background: transparent;
}

.modal-content-wrapper::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

.modal-content-wrapper::-webkit-scrollbar-thumb:hover {
  background: darkgrey;
}

.editorheading {
  margin: 10px 0 10px;
  font-size: 16px;
  color: #027fff;
  font-weight: bold;
}

.modal-option-contenteditor {
  flex: 1;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f9f9f9;
  min-height: 150px;
  max-height: 350px;
  margin-top: 20px;
}

.modal-p-contenteditor {
  flex: 1;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f9f9f9;
  overflow-y: auto;
  min-height: 150px;
  max-height: 300px;
}

.modal-p-contenteditor p {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  white-space: pre-wrap;
}

.modal-contenteditor {
  flex: 1;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f9f9f9;
  overflow-y: auto;
  min-height: 150px;
  max-height: 300px;
}

.modal-contenteditor p {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  white-space: pre-wrap;
}

.tab-contenteditor {
  flex: 1;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f9f9f9;
  overflow-y: auto;
  min-height: 150px;
  max-height: 150px;
}

.chatbot-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: #fff;
  border: 1px solid #027fff;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 93%;
  max-width: 600px;
  margin: 0 auto 25px;
  position: absolute;
  bottom: 0;
}

.chatbot-icon {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .message-pair {
  margin-top: 20px;
} */

.chat-message {
  margin-top: 20px;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 1.5;
  max-width: 80%;
}

.user-message {
  align-self: flex-end;
  background: #f5f5f5;
  color: black;
}

.ai-message {
  align-self: flex-start;
  background: #f5f5f5;
  color: #333;
}

.icon-background {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-image {
  width: 100%;
  height: 100%;
}

.chatbot-input {
  flex: 1;
  padding: 10px 12px;
  font-size: 14px;
  border: none;
  outline: none;
  color: black;
}

.chatbot-input::placeholder {
  color: #aaa;
}

.chatbot-send-button {
  color: #027fff;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
  transition: transform 0.2s ease;
}

.chatbot-send-button:hover {
  transform: scale(1.1);
}

.form-actions {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  gap: 5px;
}

.Regenerate-button {
  background-color: white;
  padding: 8px 12px;
  border: 1px solid #027fff;
  color: #027fff;
  border-radius: 4px;
}

.insert-button {
  background-color: #027fff;
  padding: 8px 12px;
  border: 1px solid #027fff;
  color: white;
  border-radius: 4px;
}

.Regenerate-button:hover {
  background: whitesmoke;
  color: #027fff;
  border-color: #027fff;
}

.insert-button:hover {
  background: white;
  color: black;
  border-color: #027fff;
}

.tabs {
  display: flex;
  gap: 10px;
  position: relative;
  border-bottom: 2px solid #e0e0e0;
  border-top: 2px solid #e0e0e0;
}

.tab {
  padding: 10px 20px;
  background: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: 14px;
  color: #1f1f1f;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s ease, color 0.3s ease;
}

.tab.active {
  border-bottom: 2px solid #0063c3;
  color: #0063c3;
}

.tab-content {
  margin-top: 10px;
  font-size: 14px;
  color: #4f4f4f;
  line-height: 1.6;
  height: 300px;
  overflow: hidden;
}


/* citation modal */

.modal-citation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  overflow-y: auto;
}

.modal-citation-container {
  background: white;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease-out;
  overflow-y: auto;
}


@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}


.modal-citation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #f4f4f4;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 1;
}

.modal-citation-header h4 {
  margin: 0;
  font-size: 18px;
}

.close-citation-btn {
  background: none;
  border: none;
  font-size: 24px;
  color: black;
  cursor: pointer;
}

.modal-citation-body {
  padding: 16px;
  overflow-y: auto;
  flex-grow: 1;
}

.citation-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 120px;
  overflow-y: auto;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

.citation-card:hover {
  border: 1px solid #027fff;
  box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.3);
}

.citation-card p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.form-actionsbutton {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: auto;
}

.generate-content {
  padding: 10px 20px;
  font-size: 16px;
  background: #027fff;
  color: white;
  border: 1px solid #027fff;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  gap: 5px;
}

.generate-content:hover {
  background: white;
  color: black;
  border-color: #027fff;
}

.loading-heading-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-heading-box {
  background-color: white;
  padding: 40px !important;
  border-radius: 10px;
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.2),
    0 -4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  width: 400px;
  height: 230px;
}

.loading-heading-editor-animation {
  width: 100px;
  height: auto;
  margin: 0;
}

.loading-heading-dots {
  width: 60px;
  height: auto;
  margin-top: 7px;
  padding: 0;

}

.loading-heading-text {
  font-size: 20px;
  color: black;
  margin: 0;
  padding: 0;
}

.loading-heading-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  margin: 0;
  padding: 0;
}

.loading-heading-div span {
  margin-right: -10px;
}

.plagiarism-contenteditor {
  flex: 1;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f9f9f9;
  overflow-y: auto;
  height: 60%;
  width: 100% !important;
}

.ql-editor.left-image-layout {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  padding: 10px;
}

.ql-editor.left-image-layout p {
  flex: 0 0 auto;
  width: 150px;
  height: auto;
  object-fit: contain;
}

.ql-editor.left-image-layout p {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 10px;
}

.plagiarism-modal {
  height: 100%;
  width: 50% !important;
  margin-left: 10px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
}

.modal-plagiarism-contenteditor {
  flex: 1;
  padding: 15px;
  background: white;
  overflow-y: auto;
}

.editor-plagiarism-heading {
  padding: 5px;
  margin-left: 5px;
  font-size: 16px;
  color: #027fff;
  font-weight: bold;
}

.plagiarism-result {
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-actions-plagiarism {
  background: #fff;
  padding: 10px;
  border-top: 1px solid #ddd;
  display: flex;
  position: sticky;
  bottom: 0;
  z-index: 1;
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  gap: 5px;
}

.plagiarism-result p {
  margin: 5px 0;
}

.plagiarism-result a {
  color: #0066cc;
  text-decoration: none;
}

.plagiarism-result a:hover {
  text-decoration: underline;
}

.download-options {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  right: 5;
  top: 100%;
  z-index: 10;
  min-width: 105px;
}

.download-options div {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.download-options div:hover {
  background: #f1f1f1;
}

.download-options img {
  width: 20px;
  height: 20px;
}


@media (max-width: 1024px) and (min-width: 768px) {
  .ql-toolbar.ql-snow {
    padding: 7px; 
  }

  .ql-container.ql-snow {
    font-size: 14px; 
    height: 480px;
  }

  .ql-editor {
    min-height: 200px; 
  }

  .ql-toolbar.ql-snow button {
    width: 28px; 
    height: 28px;
  }

  .editor-buttons {
    top: -65px; 
  }

  .save-button,
  .download-button,
  .share-button {
    padding: 8px 16px; 
    font-size: 12px; 
  }

  .form-actionsbutton {
    right: 20px; 
    bottom: 20px; 
  }

  .generate-content { 
    padding: 8px 16px;
    font-size: 14px; 
  }
}