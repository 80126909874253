// mixins
@mixin widthHeight($width, $height) {
  width: #{$width}px;
  height: #{$height}px;
}

@mixin flexContainer($align-items: null, $justify-content: null) {
  display: flex;

  @if $align-items != null {
    align-items: $align-items;
  }

  @if $justify-content != null {
    justify-content: $justify-content;
  }
}
