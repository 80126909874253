.mask-box {
  border-radius: 20px;
  position: relative;
  background: white;
  margin-left: 15px;
  border: 1px solid #D8D8D8;
}
.mask-box .mask {
  width: 100px;
  height: 40px;
  border-radius: 20px;
  background: #4C85F7;
  position: absolute;
  transition: all 0.2s ease;
}
.mask-box .MuiButton-root {
  border-radius: 20px;
  width: 100px;
  height: 40px;
  font-weight: bold;
  transition: all 0.2s 0.1s ease;
  text-transform: none !important;
  white-space: nowrap;
  font-size: 15px;
}
.mask-box .MuiButton-root:hover {
  border-radius: 18px;
  background-color: transparent !important; 
}

@media (max-width: 768px) {
  .mask-box {
    border-radius: 15px;
    margin-left: 10px;
  }
  
  .mask-box .mask {
    width: 90px; 
    height: 35px; 
    border-radius: 15px;
  }

  .mask-box .MuiButton-root {
    width: 90px; 
    height: 35px; 
    font-size: 13px; 
  }
}

@media (max-width: 1024px) {
  .mask-box {
    border-radius: 15px;
    margin-left: 10px;
  }
  
  .mask-box .mask {
    width: 90px; 
    height: 35px; 
    border-radius: 15px;
  }

  .mask-box .MuiButton-root {
    width: 90px; 
    height: 35px; 
    font-size: 13px; 
  }
}