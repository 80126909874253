.rs-table-cell-header {
  color: white !important;
  font-weight: bold !important;
  font-size: 15px;
}
.rs-table-cell {
  background-color: white !important;
}

#header {
  /* background-color: #6b70ee !important; */
  background: rgb(244, 243, 243) !important;
  color: black !important;
  /* height: 1% !important; */
}

#content {
  font-size: 15px;
  color: black !important;
}
.rs-table-body-wheel-area {
  background-color: white !important;
  width: 1080px;
}
.rs-table-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

.custome-table {
  width: 50%;
}

.table-container {
  width: 100%;
  overflow-x: auto; 
}

.custom-table {
  width: 100%;
  min-width: 800px; 
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
}

@media screen and (max-width: 768px) {
  .custom-table {
    min-width: 600px;
  }
}

@media screen and (max-width: 480px) {
  .custom-table {
    min-width: 400px;
  }
}


.addPersona {
  box-sizing: border-box !important;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
}

.rs-table-body-row-wrapper {
  background-color: white;
}

/* .rs-table-row-header {
  height: 60px !important;
  align-items: center !important;
} */

.kpiHeading {
  display: grid;
  gap: 2%;
}
.FormatKpi {
  text-align: left;
  width: fit-content;
  border: 1px solid #707070;
  border-radius: 26px;
  padding: 5px 15px;
  font-size: 0.7rem;
}

a {
  text-decoration: none !important;
}

.adminSearch {
  border-radius: 25px;
  border: 1px solid gray;
  padding: 10px 15px 10px 15px;
}

input:focus {
  outline: none;
}
