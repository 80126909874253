@import "src/scss/colors";
@import "src/scss/variables";

.logout-details {
  padding-left: 5%;
  &__firstitem {
    margin-top: 10px;
  }
  &__seconditem {
    margin-top: 20px;
  }
  &__iconwrap {
    @include flexContainer(center, null);
  }
}
