.sidebar {
  background: #4c85f7;
  color: white !important;
  transition: all 0.3s;
}

.sidebarMax {
  min-width: 20.5%;
  max-width: 20.5%;
  transition: all 0.3s;
}

.sidebarMin {
  min-width: 4.5%;
  max-width: 4.5%;
  transition: all 0.3s;
}

.genInTitle {
  font-size: 1.1rem;
  font-weight: bold;
}

.clearHistoryTitle {
  font-size: 0.8rem;
}

.heightDummy {
  height: 37.5vh;
}

.sidebar1 {
  height: 80vh;
  overflow: hidden;
  padding: 1em 1em 0em 1em;
  overflow-y: scroll;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 12%;
}

.options p {
  margin-top: 2%;
  font-size: 12px;
  color: white !important;
}

.filterOptions {
  margin-bottom: 33.5%;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.activeitems {
  padding: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.05);
  opacity: 1;
}

.sidebartoggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1%;
  padding-right: 3%;
}

.show1 {
  display: grid;
  grid-template-columns: 6% 80%;
  margin-left: 2%;
}

.line {
  border-left: 1px solid #6b70ee;
  width: 2% !important;
}

.adminOptions {
  margin-left: 2%;
}

.react-select-container {
  color: black !important;
}

.css-1nmdiq5-menu {
  color: black !important;
}

.filterAlignUnset {
  align-items: flex-start;
}

#geneInspectorText {
  color: #000000;
  width: 180px;
  outline: unset;
  margin: 10px 0;
}

.geneInFileInput {
  font-size: 12px;
}

.searchGenBtn {
  border: 1px solid white;
  background-color: #ffffff;
  width: 100px;
  margin-left: 15px;
}

.geneTextInputWrap {
  margin: 10px 0px;
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.geneFileUploadWrap {
  width: 100%;
}

.geneDisesInput {
  color: #000000;
}

.contentManagerAlign {
  flex-direction: column;
  justify-content: space-between;
  display: flex !important;
  height: 90%;
}

.genefileUploadbtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.genefileUploadbtn label {
  background-color: #e9356f;
  color: white;
  padding: 0.5rem;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 12px;
}

.genefileBtn {
  display: flex;
  align-items: center;
}

#file-chosen {
  margin-left: 0.3rem;
  margin-top: 7px;
  font-size: 12px;
}

@media (max-width: 768px) {
  .sidebar {
    position: absolute;
    left: -400px;
    top: 0;
    height: 100vh;
    width: 400px;
    background: #4c85f7;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
  }

  .sidebar.sidebarMax {
    min-width: 30%;
    max-width: 30%;
    transition: all 0.3s;
    width: 400px !important;
    left: 0;
  }

  .menuIcon {
    position: fixed;
    top: 11px;
    left: 10px;
    cursor: pointer;
    z-index: 1100;
    color: white;
    background: #4c85f7;
    padding: 8px;
    border-radius: 50%;
  }

}

@media (max-width: 1024px) {
  .sidebar {
    position: absolute;
    left: -400px;
    top: 0;
    height: 100vh;
    width: 400px;
    background: #4c85f7;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
  }

  .sidebar.sidebarMax {
    min-width: 30%;
    max-width: 30%;
    transition: all 0.3s;
    width: 400px !important;
    left: 0;
  }

  .menuIcon {
    position: fixed;
    top: 11px;
    left: 10px;
    cursor: pointer;
    z-index: 1100;
    color: white;
    background: #4c85f7;
    padding: 8px;
    border-radius: 50%;
  }

}