.modalconent {
  display: flex;
  flex-direction: column;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
}

.viewSource {
  font-size: 0.8rem;
  text-decoration: underline;
  cursor: pointer;
  color: #4c85f7;
}
