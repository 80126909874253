.displayFlexCenter
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.mic-option {
  position: absolute;
}

.query {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 55px;
    align-items: center;
  
  }
  
  .input-container {
    position: relative;
    width: 90%;
    border-radius: 50%;
    display: flex;
    align-items: center;
  }
  
  .input-field1 {
    padding-right: 50px !important;
    width: 100%;
    height: 60px;
    border: none;
    border-bottom: 2px solid #62b5b4;
    font-size: 16px;
  }
  
  .input-field1:focus {
    outline: none;
  }

  .paddingLeftInput
  {
    padding-left: 35px;
  }
  
  .input-field2 {
    padding-right: 50px !important;
    width: 100%;
    height: 60px;
    border-radius: 7px;
    border: none;
    padding-left: 2%;
    font-size: 16px;
  }
  .icon1 {
    position: absolute;
    right: 13px;
    cursor: pointer;
  }
  .icon1 > img{
    width: 30px;
  }
  
  .icon2 {
    position: absolute;
    left: 5px;
    height: 100%;
    cursor: pointer;
    box-sizing: border-box;
    padding: 8px 6px;
    border-right: 4px solid #1c274c;
  }
  

  .iconHover:hover
  {
    cursor: pointer;
  }

.font400 {
  font-weight: 400;
}

.font500 {
  font-weight: 500;
}

.font600 {
  font-weight: 600;
}

.font700 {
  font-weight: 700;
}

.font800 {
  font-weight: 800;
}

.font900 {
  font-weight: 900;
}
.fontBold
{
  font-weight: bold;
}

.fontSize0-1 {
  font-size: 0.1rem;
}

.fontSize0-2 {
  font-size: 0.2rem;
}

.fontSize0-3 {
  font-size: 0.3rem;
}

.fontSize0-4 {
  font-size: 0.4rem;
}

.fontSize0-5 {
  font-size: 0.5rem;
}

.fontSize0-6 {
  font-size: 0.6rem;
}

.fontSize0-7 {
  font-size: 0.7rem;
}

.fontSize0-8 {
  font-size: 0.8rem;
}

.fontSize0-9 {
  font-size: 0.9rem;
}

.fontSize1 {
  font-size: 1rem;
}

.fontSize10 {
  font-size: 10rem;
}

.postionRelative
{
  position: relative;
}

.postionAbsolute
{
  position: absolute;
}



.padding10
{
  padding: 10px;
}
.padding0px
{
  padding: 0px !important;
}

.paddingLeft3px
{
  padding-left: 3px !important;
}




/* top */
.top50px
{
  top: 50px;
}

.loaderCenter
{
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%,-50%);
  width: 100%;
}


.left50Percentage
{
  left: 50%;
}
.left3Percentage
{
  left: 3%;

}

.width10
{
  width: 10%;
}
.width20
{
  width: 20%;
}
.width60
{
  width: 60%;
}
.width40
{
  width: 40%;
}

.width80
{
  width: 80%;
}
.width90
{
  width: 90%;
}
/* margin */

.margin10px
{
  margin: 10px;
}
.margin15px
{
  margin: 15px;
}
.marginLeft4px
{
  margin-left: 4px;
}
.marginTop4px
{
  margin-top: 4px;
}
.marginTop1px
{
  margin-top: 1px;
}
.marginTop2px
{
  margin-top: 2px;
}
.marginTop10px
{
  margin-top: 10px !important;
}
/* margin */




.colorRed
{
  color: red;
}

.outlineNone
{
  outline: none;
}

.lightBlueBtn
{
  background: #4c85f7;
    padding: 7px 10px;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    margin-left: 10px;
}