.SearchContent { 
  padding-top: 2%; 
  display: flex; 
  justify-content: space-around; 
  width: 100%; 
  flex-direction: column; 
} 

.searchdata { 
  border-radius: 25px 0px 0px 25px; 
  border: 1px solid gray; 
  padding: 13px 15px 13px 45px; 
  /* background: yellow; */ 
}

.searchdata:focus, 
.adminSearch:focus { 
  border: 1px solid grey; 
  outline: none; 
} 

.summaryColumn>.rs-table-cell { 
  width: 400px; 
} 

#header1 { 
  background: #464646 !important; 
  color: #ffffff !important; 
} 

.custom-row { 
  max-height: 75px !important; 
  overflow: scroll; 
} 

.custom-row-article { 
  max-height: 68px !important; 
  overflow: scroll;  
} 

.summarycell { 
  display: flex; 
  flex-direction: column; 
} 

.pubmedsummary { 
  display: flex; 
  justify-content: flex-start; 
  max-height: 45px; 
  overflow: scroll; 
} 

.object-container { 
  margin-top: 15px; 
  display: flex; 
  flex-direction: row; 
  overflow-x: auto !important; 
  min-height: 23px; 
  max-height: 20px; 
  gap: 2%; 
  flex-wrap: wrap; 
  max-width: 100%; 
} 

.key { 
  /* flex: 1; */ 
  /* margin-bottom: 1%; */ 
  white-space: nowrap; 
  font-weight: 500; 
  /* font-size: 0.9rem; */ 
  cursor: pointer; 
  background-color: whitesmoke; 
  padding: 1px 8px; 
  border-radius: 5px; 
  cursor: pointer; 
} 

.actionCell { 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  text-align: center; 
  font-size: 15px; 
} 

.similarPapers {  
  padding: 10px; 
  border-radius: 6px; 
  text-align: center; 
  color: white; 
  background-color: #4c85f7; 
  cursor: pointer; 
  box-shadow: 0px 0px 2px 2px rgba(228, 227, 227, 0.15); 
  font-size: 0.9rem; 
  height: 40px;  
} 

.pubmedSearchbar { 
  position: relative; 
  display: flex; 
  align-items: center; 
  width: 100%; 
  /* display: flex; */ 
  /* justify-content: center; */ 
} 


.diseaseNameMain { 
  /* z-index: 98; */ 
}
 
.overAllSummaryWrap .overAllTitle { 
  font-size: 16px; 
  font-weight: 600; 
  padding-left: 20px; 
} 

.overAllSummaryWrap .overAllDes { 
  font-size: 14px; 
  line-height: 24px; 
  background-color: #8080801c; 
  padding: 20px; 
  border-radius: 10px; 
} 

.pubmedSearchInput { 
  background-color: rgb(76, 133, 247); 
  color: white; 
  padding: 13px 25px; 
  border-radius: 0px 25px 25px 0px; 
} 
 
.pubmedSearchInput:focus { 
  outline: 0;  
  box-shadow: unset; 
} 

.bgwhite { 
  background-color: white; 
  z-index: 2;
} 
 
.searchInputZindex { 
  z-index: 3; 
} 

.summarizeBtnWrap { 
  margin-top: 20px; 
  display: flex; 
  justify-content: space-between; 
} 

.pubmedInputContainer .width100 { 
  width: 90%; 
  display: flex; 
  align-items: center; 
} 

.pubmedInputContainer .width88 { 
  width: 88%; 
} 

.customTableHeader { 
  border-radius: 5px;  
  color: white;  
  padding: 10px;  
  background-color: #464646; 
  margin: 10px 0px; 
  position: sticky; 
  z-index: 99; 
} 
 
.top2px { 
  top: 0px; 
} 
 
.top43px { 
  top: 43px; 
} 
 
.summarizeBtnFixed { 
  position: sticky; 
  top: -10px; 
  z-index: 1; 
  background-color: white; 
  padding: 10px 0px; 
} 

.checkedImgWidth { 
  height: 18px; 
  width: 18px; 
  background-color: white; 
  cursor: pointer; 
} 

.customTableCard { 
  border-radius: 5px; 
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px; 
  margin: 10px 1px;  
} 

.pubmedPrompt { 
  overflow-y: scroll; 
  overflow-x: hidden;  
}

.referenceContainer { 
  display: flex; 
  justify-content: center; 
} 

.shareIconPubmed { 
  height: 25px; 
  width: 25px; 
  margin-right: 10px; 
} 

.fontBoldTitle { 
  font-size: 14px;  
  font-weight: 600;
  color: #0d0d0d;  
} 

.descriptionTable { 
  color: #727272; 
} 
 
.keywordSection { 
  background-color: #f7f7f7; 
  padding: 20px; 
  display: flex; 
} 
 
.keywordMain { 
  display: flex; 
  align-items: center; 
  width: 100%; 
} 

.keywordsTitle { 
  width: 10%; 
  margin-top: 2px; 
} 

.kewordsListWrap { 
  display: flex; 
  margin-left: 10px; 
  flex-wrap: wrap; 
} 

.keywordsContainer { 
  padding: 5px; 
  border: 1px solid #cac3c3 ;
  border-radius: 30px; 
  margin-right: 10px; 
  font-size: 12px; 
  display: flex; 
  align-items: center; 
  text-wrap: nowrap;
  margin-bottom: 5px; 
}

.showSimilarBtnWrap { 
  display: flex; 
  justify-content: flex-end; 
  align-items: flex-start;
  flex: 1; 
}


.similarTitle { 
  color: #4c85f7; 
  font-weight: 600; 
}

.loadingText {
  text-align: center;
  color: #007bff;
  font-weight: bold;
  margin-bottom: 10px;
}


/* .userQuestionSection {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f0f4f8;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 300px;
}

.userQuestionTitle {
  font-weight: bold;
  margin-bottom: 5px;
}

.userQuestionText {
  font-size: 14px;
  color: #333;
  word-break: break-word;
} */
