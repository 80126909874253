


.diseaseContainer
{
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
   
}
.diseaseDropContainer
{
    width: 300px;
    margin-left: 10px;
    cursor: pointer;
}
.diseaseTitle
{
    font-size: 16px;
    font-weight: 400;
}
.diseaseDropWrap
{
    display: flex;
    align-items:center;
}

.diseaseGoBack
{
    display: flex;
    align-items: center;
}

