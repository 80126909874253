@import "src/scss/colors";
@import "src/scss/variables";


.sidebar-manger {
  border-top: 1px solid $grayc1;
  border-bottom: 1px solid $grayc1;

  &__imgwrap {
    @include flexContainer(center, null);
  }
}

.sidebar-manger-special {
  border-top: 1px solid $grayc1;
  border-bottom: 1px solid $grayc1;
  margin-top: 500px !important;

 &__imgwrap {
    @include flexContainer(center, null);
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .sidebar-manger-special {
    margin-top: 450px !important;
  }
}
