.content-structure-page {
    padding: 20px;
    /* height: 250vh; */
}

.content-structure-layout {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
}

.form-container {
    flex: 1;
    padding-right: 20px;
    max-height: 430px;
    overflow-y: auto;
}

.form-container::-webkit-scrollbar {
    width: 4px;
}

.form-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.form-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.form-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.uploaded-files-container {
    flex: 1;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form {
    display: grid;
    grid-template-columns: 150px 1fr;
    gap: 20px 20px;
    align-items: center;
}

label {
    font-size: 16px;
    text-align: right;
}

.labelinputfields {
    font-size: 16px;
    text-align: right;
    width: 200px;
}

.structure-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-width: 400px;
    width: 100%;
}

.structure-input-DB {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-width: 400px;
    width: 100%;
    margin-left: 30px;
}

.form-actionsStructurebutton {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: auto;
}

.cancel-button {
    background-color: white;
    padding: 10px 20px;
    border: none;
    color: #027fff;
    border-radius: 4px;
}

.cancel-button:hover {
    background-color: whitesmoke;
}

.proceed-button {
    background-color: #027fff;
    padding: 10px 20px;
    border: none;
    color: white;
    border-radius: 4px;
    border: 1px solid #027fff;
}

.proceed-button:hover {
    background: white;
    color: black;
    border-color: #027fff;
}

.back-button {
    background: none;
    border: none;
    color: black;
    /* background-color: white; */
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;
}

/* .back-button:hover {
    background-color: whitesmoke;
} */

.top-navigation {
    margin-bottom: 10px;
}
.top-navigation-grd {
    /* margin-bottom: 10px; */
}

.contentType {
    margin-bottom: 15px;
    margin-top: 15px;
}
.contentType-Grd {
    margin-bottom: 5px;
    margin-top: 10px;
}

.uploaded-files {
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    background-color: white;
    max-height: 330px;
    display: flex;
    flex-direction: column;
}

.uploaded-files h5 {
    margin: 0;
    padding: 15px 20px;
    font-size: 16px;
    color: #027fff;
    border-bottom: 1px solid #ccc;
    background-color: white;
    flex-shrink: 0;
}

.uploaded-files-list {
    list-style: none;
    margin: 0;
    padding: 20px;
    overflow-y: auto;
    flex: 1;
}

.file-item {
    margin-bottom: 20px;
}

.file-row {
    display: flex;
    align-items: flex-start;
    gap: 15px;
}

.file-icon {
    font-size: 22px;
    color: #027fff;
    margin-top: 5px;
}

.file-content {
    flex: 1;
}

.file-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.file-name {
    font-size: 14px;
    color: #000;
}

.file-size {
    font-size: 14px;
    color: #555;
}

.status-line {
    height: 3px;
    background-color: #28a745;
    margin: 10px 0;
}

.status-text {
    font-size: 14px;
    color: green;
    text-align: left;
}

.browse-button {
    color: #027fff;
    cursor: pointer;
    padding: 8px;
    border: 2px dashed #027fff;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    justify-content: center;
    align-items: center;
    max-width: 400px !important;
    width: 100%;
}

.browse-button:hover {
    background-color: #eef7ff;
}

.browse-button-DB {
    color: #027fff;
    cursor: pointer;
    padding: 8px;
    border: 2px dashed #027fff;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    justify-content: center;
    align-items: center;
    max-width: 400px !important;
    width: 100%;
    margin-left: 30px;
}

.browse-button-DB:hover {
    background-color: #eef7ff;
}

.browse-icon {
    font-size: 20px;
    position: relative;
    top: 3px;
}

@media (max-width: 768px) {
    .file-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }

    .file-size {
        margin-top: 5px;
    }
}

.loading-heading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-heading-box {
    background-color: white;
    padding: 40px !important;
    border-radius: 10px;
    box-shadow:
        0 4px 8px rgba(0, 0, 0, 0.2),
        0 -4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    width: 400px;
    height: 230px;
}

.loading-heading-animation {
    width: 100px;
    height: auto;
    margin: 0;
}

.loading-heading-dots {
    width: 60px;
    height: auto;
    margin-top: 7px;
    padding: 0;
}

.loading-heading-text {
    font-size: 20px;
    color: black;
    margin: 0;
    padding: 0;
}

.loading-heading-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    margin: 0;
    padding: 0;
}

.loading-heading-div span {
    margin-right: -10px;
}

.dim-content {
    filter: blur(4px);
    opacity: 0.6;
    pointer-events: none;
}

.template-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
}

.template-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.template-image {
    width: 160px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
    transition: box-shadow 0.3s ease, border 0.3s ease;
}

.template-image.selected {
    border: 2px solid #007bff;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}


.upload-image-container {
    margin-top: -30px;
    margin-left: 50px;
    display: flex;
    justify-content: center;
}

.upload-text {
    color: #007bff;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    border: #007bff;
    font-size: 11px;
    position: relative;
}

.upload-text input {
    display: none;
}

.uploaded-preview {
    display: inline-flex;
    align-items: center;
    margin-top: 10px;
    position: relative;
}

.uploaded-preview img {
    width: 150px;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.close-button {
    position: absolute;
    top: -8px;
    right: -8px;
    background: #ff4d4f;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.file-name {
    flex: 1;
    font-size: 16px;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    max-width: 280px;
}

.Doc-container {
    display: flex;
    height: 73vh;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    overflow: hidden;
}

.file-upload-section {
    /* flex: 1; */
    width: 30vw;
    padding: 20px;
    border-right: 1px solid #ddd;
    background: #fff;
}

.upload-item {
    display: flex;
    flex-direction: column; 
    align-items: flex-start;
    gap: 5px;
    width: 100%;
}

.upload-header {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.file-info {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.file-details {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #eef7ff;
    padding: 5px 10px;
    border-radius: 5px;
}

.browse-button-Doc {
    display: inline-block;
    font-size: 14px;
    color: #027fff;
    padding: 10px 25px;
    border: 2px dashed #027fff;
    border-radius: 5px;
    cursor: pointer;
    background-color: #eef7ff;
    text-align: center;
    max-width: 200px !important;
    width: 100%;
}

/* .file-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 7px;
    gap: 10px;
} */

.file-nameJP {
    flex: 1;
    font-size: 16px;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    max-width: 50px;
}

.copy-icon {
    font-size: 20px;
    cursor: pointer;
    color: #027fff;
}

.action-button {
    background: none;
    border: none;
    color: #d9534f;
    font-size: 16px;
    cursor: pointer;
}

.preview-section {
    flex: 1.5;
    padding: 10px;
    display: flex;
    flex-direction: column;
    /* background: #fff; */
}

.tabs {
    display: flex;
    gap: 10px;
    position: relative;
    border-bottom: 2px solid #e0e0e0;
    border-top: 2px solid #e0e0e0;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    overflow: hidden;
}

.tab {
    padding: 10px 20px;
    background: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    font-size: 16px;
    color: #1f1f1f;
    cursor: pointer;
    outline: none;
    transition: border-color 0.3s ease, color 0.3s ease;
}

.tab.active {
    border-bottom: 2px solid #0063c3;
    color: #0063c3;
}

.tab-content-Doc {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;
    position: relative;
    overflow-y: auto;
    padding: 10px;
}

.tab-content-Doc::-webkit-scrollbar {
    width: 8px;
}

.tab-content-Doc::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.tab-content-Doc::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
}

.file-preview {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 5px;
}

.placeholder {
    font-size: 18px;
    color: #aaa;
    font-style: italic;
    text-align: center;
}

.labelDocinputfields {
    font-size: 14px;
    text-align: right;
    width: 130px;
}

@media (max-width: 1024px) and (min-width: 768px) {
    .structure-input-DB {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        max-width: 130px;
        width: 100%;
        margin-left: 30px;
    }

    .template-container {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .template-wrapper {
        flex-direction: column;
    }

    .template-image {
        width: 170px;
        height: 110px;
    }

    .browse-button-DB {
        padding: 5px;
        font-size: 14px;
        max-width: 130px !important;
    }

    .Doc-container {
         height: 55vh;
    }

}
