.navbar1 {
  height: 9vh;
}

.content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 9vh !important;
  box-sizing: border-box;
  padding: 0px 16px;
  background: #f8f8f8;
  justify-content: flex-end;
}

.kpi {
  margin-left: auto;
}

.kpiGenerateBtn {
  background: #EA356F 0% 0% no-repeat padding-box;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 0.9rem;
  margin-left: 15px;
  border-radius: 6px;
  color: white;
  border: 0.5px solid #FEFFA8;
}

.kpiGenerateBtn:hover {
  cursor: pointer;
}

.uploadNewDocBtn {
  background: #EA356F 0% 0% no-repeat padding-box;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 0.9rem;
  margin-left: 15px;
  border-radius: 6px;
  color: white;
  border: 0.5px solid #FEFFA8;
}

.uploadNewDocBtn {
 cursor: pointer;
}

.searchOption {
  margin-left: 1%;
  display: flex;
  align-items: center;
  color: #818181;
  letter-spacing: 0px;
  font-size: 0.7rem;
  cursor: pointer;
}

.navbarSelect {
  z-index: 3;
}

.currentSearch {
  margin-left: 5%;
  white-space: nowrap;
  color: #4c85f7;
  font-weight: 600;
  font-size: 1.1rem;
}

.modalHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
}

.modalButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2%;
  padding: 10px 30px 30px 10px;
}

.btn-color {
  all: unset;
  background: #4c85f7 0% 0% no-repeat padding-box;
  color: white;
}

.sendmailBtn {
  border-radius: 6px;
  padding: 8px 15px;
}

.modalUploadBox {
  padding: 20px 30px 10px;
  border: 1px dotted rgb(214, 214, 214);
  border-radius: 10px;
}

.moadalUploadInput {
  padding: 10px;
  height: 38px;
}

.documentQueryContainer {
  flex: 1;
  justify-content: space-between;
  margin-right: 10px;
}

.documentQueryContainerDS {
  flex: 1;
  justify-content: space-between;
  margin-right: 10px;
}

.context {
  margin-top: 0.75rem;
  font-size: 12px;
}

.text {
  color: #808080;
  padding: 3px;
}

.red {
  color: red;
}

.transatorimg {
  width: 70px;
  object-fit: cover;
}

.h-30 {
  height: 30px;
}

.NavbarSummarizeBtn {
  padding: 10px 20px;
  font-weight: 600;
  font-size: 0.9rem;
  margin-left: 10px;
  border-radius: 6px;
  border: 0.5px solid #FEFFA8;
  background: #FFC965;
  border: 0.5px solid black;
  opacity: 1;
  color: black;
}

@media screen and (max-width: 768px) {
  .content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    padding: 0 8px;
    height: 7vh !important;
    gap: 5px;    
  }

  .currentSearch {
    font-size: 0.9rem;
    margin-left: 2%;
  }

  .navbarSelect {
    width: 200px !important;
    font-size: 0.8rem;
    margin-right: 15px;
  }

  .navbarSelectTabView {
    width: 100px !important;
    max-width: 100px;
    font-size: 0.8rem;
    margin-right: 15px;
  }

  .searchOption {
    font-size: 0.65rem;
    margin-left: 3px;
    margin-right: 10px;
  }
 
  .searchOption img {
    margin-right: 10px;
  }

  .datasearch_smallImg {
    margin-left: 50px;
  }

  .documentQueryContainer {
    flex: 1;
    justify-content: space-between;
    margin-left: 50px;
  }

  .documentQueryContainerDS {
  flex: 1;
  justify-content: space-between;
  margin-right: 10px;
}

  .kpi {
    margin-left: auto;
  }

  .navbar1,
  .searchOption {
    margin: 0;
    padding: 0;
  }

  .navbarSelect select {
    font-size: 0.75rem;
    padding: 4px;
  }

  .navbarSelectTabView select {
    font-size: 0.5rem;
    padding: 4px;
  }

  .searchOption svg {
    width: 12px;
    height: 12px;
  }

  .kpiGenerateBtn {
    padding: 6px 12px;
    font-size: 0.8rem;
    margin-right: 5px;
  }

  .uploadNewDocBtn {
    padding: 12px 6px;
    font-size: 0.5rem;
    margin-right: 5px;
  }

  .NavbarSummarizeBtn {
    font-size: 0.5rem;  
    padding: 5px 6px;  
  }

  .TabViewToggle {
    margin-left: 40px;
  }

}

@media screen and (max-width: 1024px) {
  .content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    padding: 0 8px;
    height: 7vh !important;
    gap: 5px;
  }

  .currentSearch {
    font-size: 0.9rem;
    margin-left: 2%;
  }

  .navbarSelect {
    width: 200px !important;
    font-size: 0.8rem;
    margin-right: 15px;
  }

  .navbarSelectTabView {
    width: 100px !important;
    font-size: 0.5rem;
    margin-right: 15px;
  }

  .searchOption {
    font-size: 0.65rem;
    margin-left: 3px;
    margin-right: 10px;
  }

  .searchOption img {
    margin-right: 10px;
  }

  .datasearch_smallImg {
    margin-left: 50px;
  }

  .documentQueryContainer {
    flex: 1;
    justify-content: space-between;
    margin-left: 50px;
  }

  .documentQueryContainerDS {
  flex: 1;
  justify-content: space-between;
  margin-right: 10px;
}

  .kpi {
    margin-left: auto;
  }

  .navbar1,
  .content,
  .kpiGenerateBtn,
  .searchOption {
    margin: 0;
    padding: 0;
  }

  .navbarSelect select {
    font-size: 0.75rem;
    padding: 4px;
  }

  .navbarSelectTabView select {
    font-size: 0.5rem;
    padding: 4px;
  }

  .searchOption svg {
    width: 12px;
    height: 12px;
  }

  .kpiGenerateBtn {
    padding: 6px 12px;
    font-size: 0.8rem;
    margin-right: 5px;
  }

  .uploadNewDocBtn {
    padding: 12px 6px;
    font-size: 0.5rem;
    margin-right: 5px;
  }

  .NavbarSummarizeBtn {
    font-size: 0.5rem;  
    padding: 5px 6px;  
  }

  .TabViewToggle {
    margin-left: 40px;
  }
}