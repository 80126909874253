@import "src/scss/colors";

.btns {
  background: $btnred_6f;
  font-size: 1rem !important;
  border: none;
  border-radius: 6px;
  color: $white;
  font-weight: 400;
  padding: 7px 15px;
  outline: none !important;
  &:hover {
    cursor: pointer;
    background: linear-gradient(to right, $blue_b7, $primary);
  }
}

@media (max-width: 768px) {
.btns {
    font-size: 0.7rem !important;
    padding: 6px 10px;
  }
}

@media (max-width: 1024px) {
.btns {
    font-size: 0.7rem !important;
    padding: 6px 10px;
  }
}