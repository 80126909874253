.content-page {
    font-family: Arial, sans-serif;
    margin: 20px;
}

.header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 30px;
}

.search-container {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px;
    width: 40%;
}

.search-icon {
    color: #aaa;
    font-size: 20px;
    margin-right: 8px;
}

.search-bar {
    border: none;
    outline: none;
    flex: 1;
    font-size: 16px;
}

.header-buttons {
    margin-right: 10px;
}

.header-buttons button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.filter-btn {
    background-color: #f5f5f5;
    color: #000;
}

.filter-btn:hover {
    background-color: whitesmoke;
}

.createnewcontent-btn {
    background: #027fff;
    border: 1px solid #027fff !important;
    color: #fff;
}

.createnewcontent-btn:hover {
    background: white;
    color: black;
    border-color: #027fff;
}

.section {
    margin-bottom: 30px;
    height: 500px;
    overflow-y: auto !important;
    overflow-x: auto !important;
}

/* .section {
    margin-bottom: 30px;
    height: 500px;
    overflow-y: auto !important;
} */

.section-title {
    font-size: 18px;
    margin-bottom: 15px;
    margin-top: 10px;
}


html,
body {
    overflow-x: hidden;
}

.card-wrapper {
    overflow-x: auto !important;
    overflow-y: auto;
    box-sizing: border-box;
}

.card-wrapperMin {
    width: 1110px;
}

.card-wrapperMax {
    width: 1320px;
    ;
}

.card-container {
    display: flex;
    gap: 20px;
    padding-bottom: 10px;
    justify-content: flex-start;
    flex-shrink: 0;
    overflow-x: auto;
}

/* .card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding-bottom: 10px;
    justify-items: start;
} */

.card-containerMin {
    column-gap: 14px;
    row-gap: 40px;
}

.card-containerMax {
    row-gap: 40px;
}

.cardDocGen {
    position: relative;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
}

/* .card {
    position: relative;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

.cardMax {
    width: 300px;
    height: 230px;
}

.cardMin {
    width: 265px;
    height: 225px;
}


.card-image {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
}

.card-content {
    text-align: left !important;
}

.card-title {
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  max-width: 100%;
  display: flex; 
  align-items: center; 
}

.title-container {
  display: flex; 
  align-items: center; 
  position: relative; 
}

.title-text {
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
  cursor: pointer; 
  max-width: 100%;
}

.titletooltip {
  display: none; 
  position: absolute;
  top: 0%; 
  left: 10;
  width: 100%;
  max-width: 300px;
  background-color: #027fff; 
  color: #fff; 
  padding: 0px 10px;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10; 
  white-space: nowrap; 
  overflow-x: scroll;
  text-align: left; 
}

.title-text:hover .titletooltip {
  display: block; 
}

.card-date {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #666;
    margin-bottom: 10px;
}

.card-date svg {
    margin-right: 5px;
}

.view-now {
    text-decoration: none;
    color: #027fff;
    font-weight: lighter;
    font-size: 12px;
    background-color: white;
    border: 1px solid white;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.view-now:hover {
    background-color: #027fff;
    color: white;
    transition: 0.3s;
}

.menu-container {
    position: absolute;
    top: 10px;
    right: 10px;
}

.menu-icon {
    font-size: 18px;
    color: black;
    cursor: pointer;
    background-color: white;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.tooltip-menu {
    position: absolute;
    top: 25px;
    right: 0;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 10;
    overflow-y: auto; 
    height: 190px; 
    width: 150px; 
    padding: 5px 0;
    scrollbar-width: thin;
    scrollbar-color: #a2a1a1 #f1f1f1;
}

.tooltip-menu::-webkit-scrollbar {
    width: 2px;
}

.tooltip-menu::-webkit-scrollbar-thumb {
    background-color: #a2a1a1;
    border-radius: 10px;
}

.tooltip-option {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px 15px;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid #f0f0f0;
}

.tooltip-option:hover {
    background: #f5f5f5;
}

.tooltip-option img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.tooltip-option:last-child {
    border-bottom: none;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}


.modal-box {
    background: #fff;
    width: 600px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}


.modal-header {
    padding: 15px 20px;
    background: #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.modal-header h2 {
    margin: 0;
    font-size: 18px;
}

.close-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
}

.modal-content-generation {
    padding: 20px;
}

.content-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
}

.content-card {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.5s ease;
    text-align: left !important;
}

.content-card.active {
    background: #b3e0ff;
    border: 1px solid #027fff;
    box-shadow: 0 0 10px rgba(31, 136, 241, 0.489);
}

.content-card.disabled {
  pointer-events: none; 
  background: #e0e0e0; 
  color: #a0a0a0;
  border: 1px solid #ccc;
  cursor: not-allowed;
}

.content-card.disabled .content-icon {
  color: #a0a0a0; 
}

.content-card:hover {
    background: #e6f7ff;
}

.content-icon {
    font-size: 20px;
    margin-bottom: 8px;
}

.modal-footer {
    padding: 15px 20px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    border-top: 1px solid #ddd;
}

.cancel-btn,
.proceed-btn {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 14px;
}

.cancel-btn {
    background: white;
    color: #333;
}

.cancel-btn:hover {
    background: whitesmoke;
}

.proceed-btn {
    background: #027fff;
    border: 1px solid #027fff;
    color: #fff;
}

.proceed-btn:hover {
    background: white;
    color: black;
    border-color: #027fff;
}

.filter-tooltip {
    position: absolute;
    top: 78px;
    right: 225px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 3px;
    width: 130px;
    height: 75px;
    display: flex;
    flex-direction: column;
    z-index: 10;
    overflow: hidden;
}

.date-filter {
    position: absolute;
    top: 35px;
    width: 95% !important;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
}

.filter-label {
    font-size: 14px;
}

.modaldoc {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modaldoc-content {
    background: white;
    padding: 30px;
    width: 55%;
    height: 85%;
    overflow-y: auto;
    position: relative;
    border-radius: 8px;
}

.close-doc-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
}

.loading-heading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-heading-box {
    background-color: white;
    padding: 40px !important;
    border-radius: 10px;
    box-shadow: 
        0 4px 8px rgba(0, 0, 0, 0.2),  
        0 -4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    width: 400px;
    height: 230px;
}

.loading-heading-animation {
    width: 100px;
    height: auto;
    margin: 0;
}

.loading-heading-dots {
    width: 60px;
    height: auto;
    margin-top: 7px;
    padding: 0;
}

.loading-heading-text {
    font-size: 20px;
    color: black;
    margin: 0;
    padding: 0;
}

.loading-heading-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    margin: 0;
    padding: 0;
}

.loading-heading-div span {
    margin-right: -10px;
}

.dim-content {
  filter: blur(4px); 
  opacity: 0.6; 
  pointer-events: none; 
}