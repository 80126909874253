.list {
  background-color: white;
  height: 88vh;
  overflow: scroll;
  border-radius: 10px;
  padding: 20px 20px 10px 20px;
}

.bucket {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1%;
}
.buckets {
  margin-top: 2%;
  height: 69vh;
  overflow: scroll;
  /* background-color: red; */
}

.bucketTitle
{
  color: #EA356F;
  font-weight: 600;
}
