@import "src/scss/colors";
@import "src/scss/variables";

.admin-wrap {
  width: 100%;
  height: 87vh;
  margin-top: 1%;
  padding: 0px 3%;
  &__back {
    @include flexContainer(center, null);
    font-size: 0.85rem;
    color: $primary;
    &__icon {
      color: $btnred_6f;
    }
  }

  &__tab {
    margin-top: 1%;
    border-radius: 10px;
    width: 100%;
    padding-bottom: 1%;
    height: auto;
    &__wrap {
      @include flexContainer(center, null);
      gap: 2%;
      padding: 5px 10px;
      cursor: pointer;

      &__active {
        @include flexContainer(center, null);
        border-bottom: 2px solid $btnred_6f;
        color: $btnred_6f;
        font-weight: 600;
        padding-bottom: 4px;
      }

      &__inactive {
        @include flexContainer(center, null);
        padding-bottom: 4px;
      }
    }
  }
}

.adminTable {
  display: flex;
  flex-direction: column;
  width: 100% !important;
}


@media screen and (max-width: 1024px) {
  .admin-wrap {
    height: auto;
    padding: 0px 2%;

    &__back {
      font-size: 0.75rem; 
    }

    &__tab {
      &__wrap {
        gap: 1%; 
        padding: 5px;

        &__active,
        &__inactive {
          font-size: 0.8rem; 
        }
      }
    }
  }

  .adminTable {
    font-size: 0.7rem;
  }

  img {
    width: 16px; 
    height: 16px;
  }

}

@media screen and (max-width: 768px) {
  .admin-wrap {
    padding: 0px 1%;
  }

  .admin-wrap__tab {
    &__wrap {
      flex-wrap: wrap; 
      justify-content: center;
      gap: 5px;

      &__active,
      &__inactive {  
        font-size: 0.75rem;
        padding-bottom: 2px;
      }
    }
  }

  .adminTable {
    font-size: 0.7rem;
  }

  img {
    width: 14px;
    height: 14px;
  }
}
