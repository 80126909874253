@import "src/scss/colors";
@import "src/scss/variables";

.source-sidebar {
  height: 91vh;
  background: $primary;
  color: $white;
  transition: all 0.3s;

  &__iconwrap {
    @include flexContainer(center, null);
    padding: 10px 15px;
    transition: all 0.3s;

    &__title {
      font-weight: bold;
      white-space: nowrap;
    }
    &.spacebt {
      justify-content: space-between;
    }
    &.centerbt {
      justify-content: center;
    }

    &__icon {
      @include flexContainer(center, center);
    }

    &__arrowicon {
      @include flexContainer(center, center);
    }
  }

  &__fileicon {
    gap: 15px;
    // transition: all 0.5s;
    transition: all 0.3s;
    text-align: center;
  }

  &.collapsed {
    width: 60px;
  }

  &.expanded {
    width: 280px;
  }

  &__list {
    display: flex;
    padding: 10px 8px 10px 10px;
    overflow: scroll;
    min-height: 50%;
    height: 86vh;

    &__box {
      border-radius: 3px;
      margin-bottom: 5%;
      padding: 5px 10px;
      background: $bgwhite_f7 0% 0% no-repeat padding-box;
      font-size: 0.9rem;
    }
    &__numbers {
      @include flexContainer(start, null);
      color: $black;
      &__icons {
        font-weight: bold;
        color: $blue_b7;
        display: flex;
        justify-content: space-between;
        width: 100%;
        word-break: break-all;
      }
    }
    &__nofiles {
      margin-top: 70%;
      text-align: center;
    }

    &__content {
      color: $black;
      font-size: 0.9rem;
      &.autoheight {
        height: auto;
        overflow: scroll;
      }
      &.standardheight {
        height: 80px;
        overflow: scroll;
      }
    }

    &__time {
      color: $blue_b7;
    }

    &__readmore {
      color: $blue_b7;
      padding-top: 10px;
    }
  }
}

.sourceul {
  margin: 0;
  padding-left: 10px;
  font-size: 14px;
}
.sourceli {
  margin: 15px 0;
}
.sourceDeleteIcon svg {
  font-size: 16px;
  margin-top: 0;
  padding-top: 0px;
  top: 0px;
  align-items: stretch;
  cursor: pointer;
}
.sourceDeleteIcon {
  padding-top: 2px;
}
