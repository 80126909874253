.labelFileExtract {
  font-size: 16px;
  text-align: right;
  width: 200px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: pointer;
}

.labelFileExtract:hover::after {
  content: attr(data-label); 
  position: absolute;
  left: 0;
  top: 10%;
  white-space: nowrap;
  background-color: #f9f9f9;
  color: black;
  padding: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  z-index: 10;
  width: auto;
  max-width: 450px; 
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.labelFileExtract::after {
  visibility: hidden; 
  opacity: 0; 
  transition: opacity 0.3s ease;
}

.FileExtract-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 400px;
  width: 100%;
  margin-left: 30px;
  font-size: 16px;
  resize: none; 
  overflow: hidden;
  transition: height 0.2s ease;
}

.FileExtract-input:focus {
  outline: none;
}

.content-structure-page {
    padding: 20px;
}

.loading-heading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-heading-box {
    background-color: white;
    padding: 40px !important;
    border-radius: 10px;
    box-shadow:
        0 4px 8px rgba(0, 0, 0, 0.2),
        0 -4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    width: 400px;
    height: 230px;
}

.loading-heading-animation {
    width: 100px;
    height: auto;
    margin: 0;
}

.content-structure-layout {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
}

.form-container {
    flex: 1;
    padding-right: 20px;
    max-height: 430px;
    overflow-y: auto;
}

.form-container::-webkit-scrollbar {
    width: 4px;
}

.form-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.form-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.form-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.form-FileExtract {
    display: grid;
    grid-template-columns: 150px 1fr;
    gap: 20px 150px;
    align-items: center;
}

.form-actionsStructurebutton {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: auto;
}

.cancel-button {
    background-color: white;
    padding: 10px 20px;
    border: none;
    color: #027fff;
    border-radius: 4px;
}

.cancel-button:hover {
    background-color: whitesmoke;
}

.proceed-button {
    background-color: #027fff;
    padding: 10px 20px;
    border: none;
    color: white;
    border-radius: 4px;
    border: 1px solid #027fff;
}

.proceed-button:hover {
    background: white;
    color: black;
    border-color: #027fff;
}

.back-button {
    background: none;
    border: none;
    color: black;
    background-color: white;
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
}

.back-button:hover {
    background-color: whitesmoke;
}

.top-navigation {
    margin-bottom: 20px;
}

.loading-heading-dots {
    width: 60px;
    height: auto;
    margin-top: 7px;
    padding: 0;
}

.loading-heading-text {
    font-size: 20px;
    color: black;
    margin: 0;
    padding: 0;
}

.loading-heading-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    margin: 0;
    padding: 0;
}

.loading-heading-div span {
    margin-right: -10px;
}

.dim-content {
    filter: blur(4px);
    opacity: 0.6;
    pointer-events: none;
}

.contentType {
    margin-bottom: 15px;
    margin-top: 15px;
}

@media (max-width: 768px) {
.form-container {
    flex: 1;
    padding-right: 20px;
    max-height: 350px;
    overflow-y: auto;
}
}

@media (max-width: 1024px) {
.form-container {
    flex: 1;
    padding-right: 20px;
    max-height: 350px;
    overflow-y: auto;
}
}


