.component {
  /* display: grid; */
  /* flex-direction: column; */
  /* width: 100vh; */
  /* grid-template-rows: 23% 76%; */
  /* gap: 1%; */
  /* background-color: blue; */
  /* padding: 5px 10px; */
  /* height: 91vh; */
}

.mainContent {
  position: relative;
  display: grid;
  grid-template-rows: 91% 8%;
  /* width: 100%; */
  gap: 1%;
  height: 89vh;
  /* border-radius: 8px; */
}

.history {
  overflow: scroll !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 5px; */
}

.defaultPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box !important;
  width: 70%;
  border-radius: 5px;
  font-size: 17px;
  color: #9d9d9d;
}

.chatBotImg
{
height: 120px;
width: 120px;
}

.questions {
  box-sizing: border-box;
  border-radius: 7px;
  min-height: 70px;
  /* background: #1c274c; */
  /* color: white; */
  display: flex;
  align-items: center;
  padding: 10px 10px 5px 30px;
  font-size: 1em;
}

.answers {
  /* margin-top: 10px; */
  /* border-radius: 7px; */
  min-height: 30px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  display: flex;
  align-items: flex-start;
  padding: 15px 10px 5px 25px;
  margin-bottom: 10px;
  font-size: 0.85rem;
}

.robot {
  font-size: 25px;
}




.audioplayer {
  margin-top: 1%;
  display: flex;
  align-items: center;
  gap: 2%;
  height: 70px;
}
.audioplayer1 {
  width: 90% !important;
}

.shareOptions {
  padding: 0px 15px;
  width: 100%;
}
.shareOptions:hover {
  background-color: #4949a8;
  color: white;
  cursor: pointer;
  /* padding: 10px; */
}
button#dropdownMenu1 {
  background: none !important;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #e3e3e3;
  color: Normal black;
}




.contentChangeInput
{
  z-index: 9;
}

.contentSearchInputWrap
{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  margin: 10px 0px;
}

.contentOverflow
{
  max-height: 110px;
  overflow: scroll;
}
#contentPagination{
  padding-top: 20px;
}
#contentPagination .rs-picker-toggle
{
  z-index: -1 !important;
}


.content-stepper
{
  padding: 40px;
}

.content-search
{
  height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-search .input-container
{
  width: 80%;
  
}

.content-search img{
  height: 30px;
}

.content-search  .icon1
{
  bottom: 10px;
}

.content-select
{
  display: flex;
  gap: 20px;
  justify-content: center;
  padding-top: 20px;

}

.context {
  margin-top: 0.75rem;
  font-size: 12px;
}
.text {
  color: #808080;
  padding: 3px;
}

.red {
  color: red;
}

.pipeline-text {
  margin-top: 3rem;
  display: flex; 
  justify-content: center;
}
span.input-group-text {
  padding: 0px 3px 0px 30px;
}
.custom-checkbox {
  cursor: pointer;
}
.content-select1
{
  display: flex;
  gap: 20px;
  /* justify-content: center; */
  padding-top: 20px;
  margin-left: 5.25rem;
}

.content-table-radiobtn
{
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-table-radiobtn .form-check-input
{
  position: unset !important;
  margin-left: 0px !important;
  cursor: pointer;
}

.export-btn-width
{
  width: 73% !important;
}

.text-below-button
{
  display: block !important;
  color: #808080;
  font-size: 14px;
  margin-left: 5.25rem;
}