@import "src/scss/colors";
@import "src/scss/variables";

.search-input {
  width: 100%;
  border-radius: 25px;
  border: 1px solid $lightgreen;
  padding: 10px 15px 10px 15px;
}
.input-search-btn {
  width: 100%;
  position: relative;
  &__icon {
    position: absolute;
    right: -11px;
    top: 4px;
    background-color: $btnred_6f;
    color: white;
    padding: 10px;
    border-radius: 50%;
    @include flexContainer(center, center);
    cursor: pointer;
  }
}
.width40 {
  width: 40%;
}
