@import "src/scss/colors";
@import "src/scss/variables";

.source-filter {
  // padding: 2px 15px;
  font-size: 0.9rem;
  width: 100%;
  margin-top: 5%;

  &__labelwrap {
    @include flexContainer(center, space-between);
  }

  &__inputlabel {
    @include flexContainer(center, null);
    margin-bottom: 2%;
    &__label {
      margin-left: 5px;
    }
  }
}
