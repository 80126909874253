@import "src/scss/colors";
@import "src/scss/variables";

.genewrap {
  @include flexContainer(null, space-between);
  position: relative;

  &__container {
    position: relative;
    display: grid;
    grid-template-rows: 91% 8%;
    gap: 1%;
    height: 89vh;
    width: 100%;
  }

  &__prompt {
    overflow-y: scroll;
    overflow-x: hidden;

    &__btnwrap {
      @include flexContainer(null, flex-end);
      position: sticky;
      z-index: 100;
      top: 0px;
      background-color: $white;
      width: 100%;
      padding-bottom: 15px;

      &__refresh {
        border-radius: 5px;
        padding: 0px 20px;
        font-size: 1rem;
      }
    }

    &__table {
      &__table-header {
        border-radius: 5px;
        color: $white;
        padding: 10px;
        background-color: $gray_46;
        margin: 10px 0px;
        position: sticky;
        z-index: 99;
      }
      &__table-list {
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
        margin: 10px 1px;
      }
    }
  }

  &__font-bold {
    font-size: 14px;
    font-weight: 600;
    color: #0d0d0d;
  }

  &__desc-color {
    color: #727272;
  }

  &__showmore {
    font-size: 12px;
    text-align: center;
    cursor: pointer;
  }

  .dropdown-menu.show {
    left: 0px !important;
    width: 100%;
  }

  #dropdown-basic {
    width: 200px;
  }
  #dropdown-basic:focus {
    box-shadow: unset !important;
  }
}
