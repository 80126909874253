/* .component {
  display: grid;
  width: 100vh;
  height: 91vh;
} */

.mainContent {
  position: relative;
  display: grid;
  grid-template-rows: 91% 8%;
  gap: 1%;
  height: 89vh;
}

.history {
  overflow: scroll !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.defaultPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box !important;
  width: 70%;
  border-radius: 5px;
  font-size: 17px;
  color: #9d9d9d;
}

.chatBotImg
{
height: 120px;
width: 120px;
}

.questions {
  box-sizing: border-box;
  border-radius: 7px;
  min-height: 70px;
  display: flex;
  align-items: center;
  padding: 10px 10px 5px 30px;
  font-size: 1em;
}

.answers {
  min-height: 30px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  display: flex;
  align-items: flex-start;
  padding: 15px 10px 5px 25px;
  margin-bottom: 10px;
  font-size: 0.85rem;
}

.robot {
  font-size: 25px;
}

.sentences {
  padding: 0px 10px 10px 10px;
  line-height: 2;
  max-width: 100%;
}

.prompt {
  overflow: scroll;
  padding-bottom: 0px !important;
}

.audioplayer {
  margin-top: 1%;
  display: flex;
  align-items: center;
  gap: 2%;
  height: 70px;
}
.audioplayer1 {
  width: 90% !important;
}

.shareOptions {
  padding: 0px 15px;
  width: 100%;
}
.shareOptions:hover {
  background-color: #4949a8;
  color: white;
  cursor: pointer;
  /* padding: 10px; */
}
button#dropdownMenu1 {
  background: none !important;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #e3e3e3;
  color: Normal black;
}

/* ThumbsIcons.css */
.thumbs-icons {
  display: flex;
  align-items: center !important
}

.thumbs-icon {
  font-size: 24px;
  cursor: pointer;
  margin: 0 5px;
  transition: color 0.1s ease-in-out;
  /* color: white; */
}

.thumbs-icon.active {
  color: green !important;
}
.thumbs-icon.activedown {
  color: red !important;
}
