@import "src/scss/colors";
@import "src/scss/variables";

.login {
  @include flexContainer(center, center);
  background: url("../../assets/loginPage/background.webp") center/cover
    no-repeat fixed;
  position: relative;
  height: 100vh;
  box-shadow: inset 0 0 0 2000px $blacshadow;

  &__bgwrap {
    max-width: 28vw;
    width: 100%;
    background: $white;
    border-radius: 10px;
    color: $primary;
    padding: 10px;
    text-align: center;
    
    &__title {
      margin-top: 2rem;
      color: $gray7c;
      font-weight: 600;
    }
    &__header {
      margin-top: 0.75rem;
      margin-bottom: 3rem;
    }
    &__gradient-btn {
      background: $btnred_6f;
      letter-spacing: 0.05rem;
      width: 80%;
      height: 4rem;
      font-size: 18px;
      border: none;
      border-radius: 7px;
      color: $white;
      font-weight: bold;
      margin-bottom: 2rem;
    }
  }

  @media screen and (max-width: 1024px) {
    &__bgwrap {
      max-width: 50vw;
      padding: 8px;
      border-radius: 8px;
      
      &__title {
        font-size: 1.2rem;
      }
      &__header {
        font-size: 1.5rem;
      }
      &__gradient-btn {
        width: 60%;
        height: 3.5rem;
        font-size: 14px;
        border-radius: 6px;
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    &__bgwrap {
      max-width: 50vw;
      padding: 6px;
      border-radius: 6px;
      
      &__title {
        font-size: 1.2rem;
      }
      &__header {
        font-size: 1.5rem;
      }
      &__gradient-btn {
        width: 60%;
        height: 3rem;
        font-size: 14px;
        border-radius: 5px;
      }
    }
  }
} 
