@import "scss/variables";
@import "scss/colors";
@import "scss/fonts";

.App {
  display: flex;
  height: 100vh;
  flex-shrink: 0;
}

body,
html {
  color: $black !important;
  font-size: $base-font-size !important;
  font-family: $font-family !important;
}
