.modal__list-content {
  word-wrap: break-word;

  &--height {
    height: 400px;
    overflow: scroll;
  }

  &--heading {
    font-weight: 500;
    color: #27437b;
  }
  &__reasoning {
    margin-top: 5px;
    font-size: 14px;
    line-height: 20px;
  }
  &__title {
    color: #dc3545;
    font-weight: 500;
  }
}
