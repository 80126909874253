$black: #000000;
$blacshadow: #00000061;
$lightblack: #f2e0bd26;
$lightgreen: #888b8d;
$gray_46: #464646;
$gray7c: #7c7c7c;
$graycc: #cccccc;
$grayc1: #c1c1c1;
$white: #ffffff;
$grayf5: #f5f5f5;
$bgwhite_f8: #f8f8f8;
$bgwhite_f7: #f7f7f7;
$primary: #4c85f7;
$blue_b7: #035eb7;
$btnred_6f: #ea346f;
$orange65: #ffc965;
$orangea8: #feffa8;
