.Schedulesubmitbutton {
  background-color: #4c85f7;
  border: none;
  color: white;
  padding: 10px 30px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  border-radius: 5px;
  margin-left: 150px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.Schedulesubmitbutton:hover {
  background-color: #4c85f7;
  color: white;
}

.card {
  width: 550px;
  margin: 0 auto;
  padding: 30px;
  background-color: whitesmoke;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-content {
  display: flex;
  flex-direction: column;
}

.input-field-schedule {
  margin-bottom: 16px;
}

.label {
  margin-bottom: 8px;
  font-weight: bold;
}

input[type="date"],
input[type="time"],
select {
  width: 100%;
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-container-schedule {
  display: grid;
  grid-template-columns: 23% 77%;
  align-items: center;
  width: 100%;
  margin-bottom: 5%;
  gap: 2%;
}

.scheduleContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 78vh;
  border-radius: 8px;
}
