@import "src/scss/colors";
@import "src/scss/variables";

.session-history {
  height: 33vh;
  overflow: scroll;

  &__history-list {
    @include flexContainer(center, null);
    padding: 5px 0px;
    padding-left: 15px;
    border-radius: 5px;

    &:hover {
      background-color: $lightblack;
    }

    &__icon {
      display: none;
      align-items: center;
      cursor: pointer;
    }

    &:hover &__icon {
      display: flex;
    }
  }
  &__input {
    font-size: 0.8rem;
    padding: 5px 0px;
    width: 100% !important;
    overflow: scroll;
    white-space: nowrap;
    cursor: pointer;
    &__border {
      border: none;
      padding: 2px 3px;
      color: $black;
    }
  }
}
