@import "src/scss/variables";
.searchbtn-input {
  @include flexContainer(center, space-between);
  margin: 30px 0px;

  // &__search-input {
  //   border-radius: 25px;
  //   border: 1px solid $lightgreen;
  //   padding: 10px 15px 10px 15px;
  // }
}
