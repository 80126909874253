.commonPromptFilePage-header{
    display:flex;
    height: 90vh;
    padding: 15px,
}

.promptPage{
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align:center;
    background-color: #fff;
    margin: 10px;
    overflow-y: auto;
}

.vertical-line{
    width: 0.5px;
    background-color: black;
    height: 100%,
}

.extractedFiles_page{
    flex: 1 !important;
    border: 1px solid #ccc !important;
    border-radius: "8px" !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
    padding: 20px !important;
    text-align: center !important;
    background-color: "#fff" !important;
    margin: "10px" !important;
}

.faq-quest{
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align:center;
    background-color: #fff;
    margin: 20px;
    cursor: pointer;
}

.static-questions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15vh;
  }
  
  .static-questions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  
  .faq-quest {
    margin: 10px;
    padding: 10px;
    background-color: #f3f3f3;
    border-radius: 5px;
    text-align: center;
    flex: 1 1 45%;
  }
  
  .faq-quest p {
    font-size: 14px;
    color: #333;
  }

  .input-container-callIn {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 100%;
    height: 50px;
    overflow: hidden;
 }
  
  .input-box-callIn {
    flex: 1;
    padding: 8px 12px;
    border: none;
    outline: none;
    font-size: 16px;
  }
  
  .arrow-button {
    color: white;
    border: none;
    width: 4vw;
    height: 4vh;
    cursor: pointer;
  }
  
.questAndans-container {
    display: flex;
    flex-direction: column;
    height: 83vh;
    overflow: hidden;
  }
  
  .questAndans {
    flex: 1;
    overflow-y: auto;
  }
  
  .fixed-search-input {
    position: sticky;
    bottom: 0;
    background: white;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }

  .sentences-callIn{
    padding: 0px 10px 10px 10px;
  }

  .answers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .viewSource {
    font-size: 14px;
    color: blue;
    text-decoration: underline;
  }
  